export const Models = (BrandName ) => {
    
    switch (BrandName) {
        case "Maruti Suzuki": {
            const modelsName = [" Alto Hatchback"," Wagon R Hatchback"," Swift Hatchback"," Baleno Hatchback"," Dzire Sedan"," Ertiga MPV"," Omni Van"," Esteem Sedan"," Zen Hatchback"," SX4 Sedan"," Vitara Brezza SUV"," Ignis Hatchback"," S-Cross SUV"];
            return modelsName;
        }
        case "Hyundai": {
            const modelsName = [
                " Santro Hatchback"," i10 Hatchback"," i20 Hatchback"," Venue SUV"," Creta SUV"," Verna Sedan"," Accent Sedan"," Sonata Sedan"," Tucson SUV"," Elantra Sedan"," Grand i10 Hatchback"," Xcent Sedan"," Kona Electric SUV (Electric)"

            ]
            return modelsName;
        }
        case "Tata": {
            const modelsName = [
                "Indica Hatchback","Indigo Sedan","Safari SUV","Nano Hatchback","Tiago Hatchback","Tigor Sedan","Nexon SUV","Harrier SUV","Altroz Hatchback","Safari Storme SUV","Sumo SUV","Aria MPV","Hexa SUV"

            ]
            return modelsName
        }
        case "Mahindra": {
            const modelsName = [
                "Scorpio SUV","Bolero SUV","XUV500 SUV","Thar SUV","KUV100 Hatchback","TUV300 SUV","Marazzo MPV","Verito Sedan","e2o Hatchback (Electric)","eVerito Sedan (Electric)"

            ]
            return modelsName
        }
        case "Honda": {
            const modelsName = [
                "City Sedan","Civic Sedan","Accord Sedan","Jazz Hatchback","Amaze Sedan","WR-V SUV","CR-V SUV"
            ]
            return modelsName
        }
        case "Toyota": {
            const modelsName = [
                "Innova MPV","Corolla Sedan","Camry Sedan","Fortuner SUV","Etios Sedan","Glanza Hatchback","Land Cruiser SUV","Yaris Sedan","Urban Cruiser SUV","Prius Hatchback (Hybrid)"
            ]
            return modelsName;
        }
        case "Volkswagen": {
            const modelsName = [
                "Polo Hatchback","Vento Sedan","Jetta Sedan","Tiguan SUV","Passat Sedan"
            ]
            return modelsName
        }
        case "Ford": {
            const modelsName = [
                "Figo Hatchback","Fiesta Sedan","EcoSport SUV","Endeavour SUV","Aspire Sedan","Fusion Hatchback","Classic Sedan","Freestyle Hatchback","Mustang Sports Car"

            ]
            return modelsName
        }
        case "Renault": {
            const modelsName = [
                "Duster SUV","Kwid Hatchback","Lodgy MPV","Captur SUV","Scala Sedan","Pulse Hatchback","Triber MPV"

            ]
            return modelsName
        }
        case "Nissan": {
            const modelsName = [
                "Micra Hatchback","Sunny Sedan","Terrano SUV","Kicks SUV","Evalia MPV","GT-R Sports Car"

            ]
            return modelsName
        }
        case "Chevrolet": {
            const modelsName = [
                "Spark Hatchback","Beat Hatchback","Sail Sedan","Cruze Sedan","Enjoy MPV","Tavera MPV","Captiva SUV","Aveo Sedan","Trailblazer SUV"

            ]
            return modelsName
        }
        case "Skoda": {
            const modelsName = [
                "Fabia Hatchback","Rapid Sedan","Octavia Sedan","Yeti SUV","Superb Sedan","Kodiaq SUV","Karoq SUV"

            ]
            return modelsName
        }
        case "Fiat": {
            const modelsName = [
                "Palio Hatchback","Linea Sedan","Punto Hatchback","Avventura SUV","Urban Cross SUV","Abarth Punto Hatchback","Abarth 595 Hatchback","Abarth Avventura SUV","Linea Classic Sedan"
            ]
            return modelsName
        }
        default: {
            return []
        }
    }

}
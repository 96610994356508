import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { arrayUnion, doc, setDoc, updateDoc,collection } from "firebase/firestore";
import { db } from '../../../firestore';
import toast from 'react-hot-toast';
const AddUserWrapper = ({ userDetails, addUser, UpdateUser }) => {
  function generateID() {
    return `${new Date().getDate()}-${new Date().getMonth()}-${new Date().getFullYear()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}${new Date().getMilliseconds()}`
  }

  const handleAddUser = async () => {
    const docRef = doc(db, 'customers', 'Sn3lz6kmewqCI43I5m0z'); // Replace with your actual document ID
    if (addUser) {
      const data = { ...userDetails, "uuid": generateID() }
      try {
        // Use the arrayUnion method to add a new user to the array
        await updateDoc(docRef, {
          userList: arrayUnion(data),
        });
        toast.success("User Added Successfully!")
        return true
      } catch (error) {
        toast.error("Failed To Add Users Try Later");
        return false

      }
    }
    else if (updateDoc) {
      try {
        // Use the arrayUnion method to add a new user to the array
        await updateDoc(doc(db,"customers","Sn3lz6kmewqCI43I5m0z"), {
          userList: UpdateUser,
        });
        toast.success("Data Update Successfully!")
        return true
      } catch (error) {
        toast.error("Failed To Update Data Try Later");
        return false

      }

    }


  };
  handleAddUser();

}

export default AddUserWrapper

import React from 'react'
import { useSelector } from 'react-redux'

const DataView = () => {
  const Total  = useSelector(state=>state.DataList.TotalCustomer);
  const Rating =useSelector(state=>state.DataList.Rating);
  const Supervisor = useSelector(state=>state.DataList.Supervisor);
  const MyUsers = useSelector(state=>state.DataList.MyCustomer);

  const dashboard = [
    {
      title: "Total Customers",
      count: Total,
      bg:"bg-gradient-to-r from-cyan-400 to-emerald-300"
    },
    {
      title: "Customers Ratings",
      count: Rating,
      bg:"bg-gradient-to-r from-rose-400 to-red-400"
    },
    {
      title: "Superviser",
      count: Supervisor,
      bg:"bg-gradient-to-r from-yellow-400 to-amber-200"
    },
    {
      title: "My Customers",
      count: MyUsers,
      bg:"bg-gradient-to-r from-emerald-500 to-emerald-400"
    },

  ]
  return (
    <div className='flex h-[max-content] flex-col gap-[30px] rounded-lg'>
      <div className=''>
        <h2 className='text-[20px] font-appFont font-[400]'>Hi, welcome back!</h2>
      </div>
      <div className=' flex gap-[10px] '>
        {dashboard.map((ele, index) => {
          return (
            <div className={`${ele.bg} border-[1px] w-[25%] h-[125px] p-[5px] rounded-lg shadow-md shadow-slate-200`} key={index}>
              <div className='w-full p-[5px] flex justify-start flex-col gap-2'>
                <h3 className='font-appFont font-[600] text-[#ffffff]'>{ele.title}</h3>
                <p className='font-appFont '>{ele.count}</p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DataView

import { IoGridOutline } from "react-icons/io5";
import { PiCubeDuotone } from "react-icons/pi";
import { BsPalette } from "react-icons/bs";
import { IoSettingsOutline } from "react-icons/io5";

import { IoMdNotificationsOutline } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
export const SideBarSections = [
    {
        icon:<IoGridOutline/>,
        title:"Dashboard",
        path:"dashboard",
    },
     {
        icon:<PiCubeDuotone/>,
        title:"Application",
        path:"dashboard",
    },
    {
        icon:<BsPalette/>,
        title:"Design",
        path:"dashboard",
    },
    {
        icon:<IoSettingsOutline/>,
        title:"Settings",
        path:"dashboard",
    },
];
export const HeaderTopSections = [
    
    {
        icon:<IoSearch/>,
        title:"Settings",
        path:"dashboard",
    },
    {
        icon:<IoMdNotificationsOutline/>,
        title:"Design",
        path:"dashboard",
    },
]
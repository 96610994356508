import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import BrandsModel from '../brands/BrandsModel';
import { MenuItem, Select } from '@mui/material';
import { Models } from '../brands/Models';
import ModelsPopUp from '../brands/ModelsPopUp';
import { State, City } from 'country-state-city';
import BhopalArea from '../../../app/jsons/BhopalArea.json';
import IndoreArea from '../../../app/jsons/IndoreArea.json'
import { AddUserSchema } from './SchemaAddUser';
import AddUserWrapper from '../appAdminFirebase/AddUserWrapper';
import { CgSpinner } from 'react-icons/cg';
import { useDispatch } from 'react-redux';
import { RefreshNow } from '../../../app/slices/UserSlice';
import toast from 'react-hot-toast';


const AddUserForm = ({ show }) => {

  const BrandName = BrandsModel()
  const [carBrand, setCarBrand] = useState("Select Car Brand")
  const [showModelList, setShowModelList] = useState(false)
  const [ModelsList, setModelsList] = useState([]);
  const [helper, setHelper] = useState("");
  const All_State = State.getStatesOfCountry("IN")
  const [state, setState] = useState(19);
  const [city, setCity] = useState(52);
  const [areas, setAreas] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()

  const handleBrandSelect = (e) => {
    setCarBrand(e.target.value);
    setHelper("")
  }
  const handleCarBrandModel = (modelsName) => {
    // let settedValue = `${carBrand} ${modelsName}`;
    let settedValue = `${modelsName}`;
    setHelper(settedValue)
  }
  const handleState = (value) => {
    setState(value);
    setCity(0);
    setAreas(0);

  }
  const handleCity = (value) => {
    setCity(value);
    setAreas(0);
  }
  const ClearFilters = () => {
    setCity(52);
    setState(19);
    setAreas(0)
  }

  const filters = {
    state: State.getStatesOfCountry("IN"),
    city: City.getCitiesOfState("IN", All_State[state ? state : 0]?.isoCode),
    areas: city === 52 ? BhopalArea.Bhopal : city === 102 ? IndoreArea.Indore : ["no service available"]
  }

  useEffect(() => {
    const ModelsList = Models(carBrand);
    if (ModelsList.length > 0) {
      setShowModelList(true);
      setModelsList(ModelsList)
    }
  }, [carBrand]);


  // `${carBrand?carBrand:"null"} ${helper?helper:""}`
  const { values, handleChange, touched, handleReset, handleSubmit, errors } = useFormik({
    initialValues: {
      Full_Name: "",
      Phone_Number: "",
      Vehicle_Number: "",
      Vehicle_Model: "",
      Vehicle_Brand: "",
      Vehicle_Color: "",
      Time: "",
      House_Number: "",
      Colony_and_Area: 1,
      District: 52,
      State: 19,
      Colony: "",
      Active:true
    },
    validationSchema: AddUserSchema,
    onSubmit: (values) => {
      if ((!values.District === 52 || !values.District === 102) || !values.State === 19) {
        toast.error("No Service Available At Your Area");
        return;
      } else if (values.Colony_and_Area === 0) {
        toast.error("Please Select Your Area");
        return;
      }
      setLoading(true);
      const created = AddUserWrapper({ userDetails: values,addUser:true });
      if (created) {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        setTimeout(() => {
          show(false);
          dispatch(RefreshNow(true))
        }, 3000);


      } else {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        setTimeout(() => {
          show(false)
        }, 3000);
      }
    }

  });

  // UI PART START FROM HERE
  return (
    <div className='flex w-full flex-col gap-[10px] border-[2px] p-[10px]  bg-[#ffffff] rounded-md border-[#2a877e] flex-1 shadow-lg' >


      <form className='flex w-full flex-col h-full justify-between' onSubmit={handleSubmit}>
        <div className='flex w-full justify-center  rounded-md'>
          <h2 className='text-[1.2em] font-appFont font-[600] text-[#2a877e]'>{"Add Users"}</h2>
        </div>
        <div className='flex w-full flex-col gap-[15px]'>

          <div className='flex gap-[10px]'>
            <div className='flex w-full flex-col gap-[5px]'>
              <label htmlFor='Full_Name' className='text-[13px] font-appFont font-[400] text-[#2a877e]'>{"Full Name"}</label>
              <input
                className={`border-[2px] rounded-md p-[7px] w-full outline-[#2a877e] text-[14px] text-[#2a877e]`}
                placeholder='Enter Full Name'
                name='Full_Name'
                id='Full_Name'
                type='text'
                value={values.Full_Name}
                onChange={handleChange}

              />
              {errors.Full_Name && touched.Full_Name ? <span className="text-[10px] text-[red] font-appFont ">{errors.Full_Name}</span> : null}
            </div>

            <div className='flex w-full flex-col gap-[5px]'>
              <label htmlFor='Phone_Number' className='text-[13px] font-appFont font-[400] text-[#2a877e]'>{"Phone Number"}</label>
              <input
                className={`border-[2px] rounded-md p-[7px] w-full outline-[#2a877e] text-[14px] text-[#2a877e]`}
                placeholder='Enter Phone Number'
                name='Phone_Number'
                id='Phone_Number'
                type='text'
                value={values.Phone_Number}
                onChange={handleChange}

              />
              {errors.Phone_Number && touched.Phone_Number ? <span className="text-[10px] text-[red] font-appFont ">{errors.Phone_Number}</span> : null}
            </div>

          </div>

          <div className='flex gap-[10px]'>

            <div className='flex w-full flex-col gap-[5px]'>
              <label htmlFor='Vehicle_Number' className='text-[13px] font-appFont font-[400] text-[#2a877e]'>{"Vehicle Number"}</label>
              <input
                className={`border-[2px] rounded-md p-[7px] w-full outline-[#2a877e] text-[14px] text-[#2a877e]`}
                placeholder='Enter Vehicle Number'
                name='Vehicle_Number'
                id='Vehicle_Number'
                type='text'
                value={values.Vehicle_Number}
                onChange={handleChange}

              />
              {errors.Vehicle_Number && touched.Vehicle_Number ? <span className="text-[10px] text-[red] font-appFont ">{errors.Vehicle_Number}</span> : null}
            </div>

            <div className='flex w-full flex-col gap-[5px]'>
              <label htmlFor='Phone_Number' className='text-[13px] font-appFont font-[400] text-[#2a877e]'>{"Select Car Brand"}</label>

              <Select
                value={values.Vehicle_Brand}
                className='text-[5px]'
                placeholder=''
                name='Vehicle_Brand'
                onChange={(e) => { handleBrandSelect(e); handleChange(e) }}
                size='small'
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                style={{ fontFamily: "Poppins", fontSize: "14px", width: "100%", }}
              >
                {BrandName.map((ele, index) => {
                  return (
                    <MenuItem key={index} value={ele} style={{ fontFamily: "Poppins", fontSize: "14px" }}>{ele}</MenuItem>
                  )
                })}

              </Select>
              <span className='text-[12px] font-appFont font-[400]'>{helper}</span>
              {errors.Vehicle_Brand && touched.Vehicle_Brand ? <span className="text-[10px] text-[red] font-appFont ">{errors.Vehicle_Brand}</span> : null}
              {!errors.Vehicle_Brand && errors.Vehicle_Model && touched.Vehicle_Model ? <span className="text-[10px] text-[red] font-appFont ">{errors.Vehicle_Model}</span> : null}


            </div>


          </div>

          <div className='flex gap-[10px]'>

            <div className='flex w-full flex-col gap-[5px]'>
              <label htmlFor='Vehicle_Color' className='text-[13px] font-appFont font-[400] text-[#2a877e]'>{"Vehicle Color"}</label>
              <input
                className={`border-[2px] rounded-md p-[7px] w-full outline-[#2a877e] text-[14px] text-[#2a877e]`}
                placeholder='Vehicle Color'
                name='Vehicle_Color'
                id='Vehicle_Color'
                type='text'
                value={values.Vehicle_Color}
                onChange={handleChange}

              />
              {errors.Vehicle_Color && touched.Vehicle_Color ? <span className="text-[10px] text-[red] font-appFont ">{errors.Vehicle_Color}</span> : null}

            </div>

            <div className='flex w-full flex-col gap-[5px]'>
              <label htmlFor='Time' className='text-[13px] font-appFont font-[400] text-[#2a877e]'>{"Time"}</label>
              <input
                className={`border-[2px] rounded-md p-[7px] w-full outline-[#2a877e] text-[14px] text-[#2a877e]`}
                name='Time'
                id='Time'
                type="time"
                value={values.Time}
                onChange={handleChange}

              />
              {errors.Time && touched.Time ? <span className="text-[10px] text-[red] font-appFont ">{errors.Time}</span> : null}

            </div>

          </div>

          <div className='flex gap-[10px]'>

            <div className='flex w-[calc(50%-5px)] flex-col gap-[5px]'>
              <label htmlFor='State' className='text-[13px] font-appFont font-[400] text-[#2a877e]'>{"State"}</label>
              <Select
                value={values.State}
                className='text-[5px] w-[100%]'
                placeholder='Select State'
                onChange={(e) => { handleState(e.target.value); handleChange(e) }}
                size='small'
                name='State'
                id='State'
                style={{ fontFamily: "Poppins", fontSize: "14px" }}
              >
                {filters.state.map((ele, index) => {
                  return (
                    <MenuItem key={index} value={index} style={{ fontFamily: "Poppins", fontSize: "14px" }}>{ele.name}</MenuItem>
                  )
                })}

              </Select>
              {errors.State && touched.State ? <span className="text-[10px] text-[red] font-appFont ">{errors.State}</span> : null}

            </div>

            <div className='flex w-[calc(50%-5px)] flex-col gap-[5px]'>
              <label htmlFor='District' className='text-[13px] font-appFont font-[400] text-[#2a877e]'>{"District"}</label>
              <Select
                value={values.District}
                className='text-[5px] w-[100%]'
                placeholder='Select District'
                onChange={(e) => { handleCity(e.target.value); handleChange(e) }}
                size='small'
                name='District'
                id='District'
                style={{ fontFamily: "Poppins", fontSize: "14px" }}
              >
                {filters.city.map((ele, index) => {
                  return (
                    <MenuItem key={index} value={index} style={{ fontFamily: "Poppins", fontSize: "14px" }}>{ele.name}</MenuItem>
                  )
                })}

              </Select>
              {errors.District && touched.District ? <span className="text-[10px] text-[red] font-appFont ">{errors.District}</span> : null}

            </div>

          </div>

          <div className='flex gap-[10px]'>

            <div className='flex w-[calc(50%-5px)] flex-col gap-[5px]'>
              <label htmlFor='House_Number' className='text-[13px] font-appFont font-[400] text-[#2a877e]'>{"House Number "}</label>
              <input
                className={`border-[2px] rounded-md p-[7px] w-full outline-[#2a877e] text-[14px] text-[#2a877e]`}
                placeholder='House Number'
                name='House_Number'
                id='House_Number'
                type='text'
                value={values.House_Number}
                onChange={handleChange}

              />
              {errors.House_Number && touched.House_Number ? <span className="text-[10px] text-[red] font-appFont ">{errors.House_Number}</span> : null}

            </div>
            <div className='flex w-[calc(50%-5px)] flex-col gap-[5px]'>
              <label htmlFor='Colony' className='text-[13px] font-appFont font-[400] text-[#2a877e]'>{"Colony Name "}</label>
              <input
                className={`border-[2px] rounded-md p-[7px] w-full outline-[#2a877e] text-[14px] text-[#2a877e]`}
                placeholder='Colony Name'
                name='Colony'
                id='Colony'
                type='text'
                value={values.Colony}
                onChange={handleChange}

              />
              {errors.Colony && touched.Colony ? <span className="text-[10px] text-[red] font-appFont ">{errors.Colony}</span> : null}

            </div>
            
          </div>
          <div>
          <div className='flex w-full flex-col gap-[5px]'>
              <label htmlFor='Colony_and_Area' className='text-[13px] font-appFont font-[400] text-[#2a877e]'>{"Area"}</label>
              <Select
                value={values.Colony_and_Area}
                className='text-[5px]'
                placeholder='Select State'
                onChange={(e) => { setAreas(e.target.value); handleChange(e) }}
                size='small'
                id='Colony_and_Area'
                style={{ fontFamily: "Poppins", fontSize: "14px", width: "100%" }}
                name={'Colony_and_Area'}

              >
                {filters.areas.map((ele, index) => {
                  return (
                    <MenuItem key={index} value={index} style={{ fontFamily: "Poppins", fontSize: "14px" }}>{ele}</MenuItem>
                  )
                })}

              </Select>
              {errors.Colony_and_Area && touched.Colony_and_Area ? <span className="text-[10px] text-[red] font-appFont ">{errors.Colony_and_Area}</span> : null}

            </div>
          </div>


        </div>

        <div className='flex w-full items-center border-[1px] gap-[10px] justify-center border-emerald-600 bg-emerald-600 p-[5px] rounded'>
          {loading ?
            <span className=''>
              <CgSpinner size={25} className="mt-1 animate-spin text-[#ffffff]" />
            </span> :
            <button type="submit"
              className='text-[16px] w-full font-appFont text-[#ffffff] outline-none border-none'>Submit User Details</button>
          }
        </div>
      </form>
      <ModelsPopUp handleChange={handleChange} values={values} helper={helper} data={ModelsList ? ModelsList : []} showModelList={showModelList} setShowModelList={setShowModelList} handleCarBrandModel={handleCarBrandModel} bounce={!errors.Vehicle_Brand && errors.Vehicle_Model && touched.Vehicle_Model}/>

    </div>
  )
}

export default AddUserForm

import * as Yup from "yup";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export  const AddUserSchema = Yup.object().shape({
    Full_Name: Yup.string().min(3,"Name At Least 3 Char").max(35,"Name Not More Than 35 Char").required("Please Enter Full Name").trim(),
    Phone_Number:Yup.string()
    .required("Please Enter Phone Number")
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(10, "Phone Number Exactly 10 digit")
    .max(10, "Phone Number Exactly 10 digit"),
    Vehicle_Number: Yup.string().min(6).max(10).required("Please Enter Vehicle Number").trim(),
    Vehicle_Model: Yup.string().required("Please Select Car Model"),
    Vehicle_Brand: Yup.string().required("Please Select Car Brand"),
    Vehicle_Color: Yup.string().min(3).max(10).required("Please Enter Vehicle Color").trim(),
    Time: Yup.string().required("Please Select Time Slot"),
    House_Number: Yup.string().min(1).max(10).required("Please Enter House Number"),
    Colony_and_Area: Yup.string().required("Please Select Area ").trim(),
    District: Yup.number().required("Please Select District"),
    State: Yup.number().required("Please Select State"),
    Colony:Yup.string().min(3,"Colony Name At Least 3 char").required("Please Enter Colony").trim(),

  });
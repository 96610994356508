import React, { useEffect } from 'react'
import AdminHeader from './AdminHeader'
import AdminLayout from './AdminLayout'
import { useCookies } from 'react-cookie';
import { Outlet, useNavigate } from 'react-router-dom';
const Admin = () => {

  const [cookies, setCookie, removeCookie] = useCookies(['name']);
  const navigate = useNavigate();

  useEffect(() => {

    if (cookies?.gowashe_token === "otKKqAgtNTR9oUQgQ3WnmOBM5rr2") {
      navigate("/admin")
    } else {
      navigate("/admin/login")
    }
  })

  return (
    <div className='flex w-[100%] bg-white min-h-screen flex-col'>
      <AdminHeader removeCookie={removeCookie} />
      {/* <Outlet/> */}
      <AdminLayout />
    </div>
  )
}

export default Admin

import React, { useEffect, useState } from 'react'
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
const ModelsPopUp = ({ data, showModelList, setShowModelList, handleCarBrandModel, handleChange, values,bounce }) => {
    const [select, setSelect] = useState(0);
    const [selectData, setSelectData] = useState([]);

    useEffect(() => {
        setSelectData(data ? data : [])
        if (data) {
            setSelect(0);
        } else if (data.length === 0) {
            hideIfNull()
        }
    }, [data]);

    function hideIfNull() {
        setTimeout(() => {
            setShowModelList(false)
        }, 5000);

    }
    function hide() {
        setTimeout(() => {
            setShowModelList(false)
        }, 500);

    }
    function showList() {
        setShowModelList(true)
    }

    return (
        <div className={`flex w-[270px] h-[100vh] flex-row-reverse bg-transparent fixed inset-0 ${showModelList ? 'left-[calc(50%+210px)] transition-all duration-700 z-1' : 'left-[calc(47%)]  transition-all duration-500 z-[-1]'}  items-center justify-center`}>
            <div className={`text-[25px] text-[#2a877e] cursor-pointer ${bounce?"animate-bounce text-[red]":""}`} onClick={showModelList ? hide : showList}>{showModelList ? <MdKeyboardDoubleArrowLeft /> : <MdOutlineKeyboardDoubleArrowRight />}</div>
            <div className='flex w-full  h-[90vh]  p-[7px] bg-[#2a877e] rounded-lg shadow-lg '>
                <div className='flex flex-col w-full bg-[#ffffff] rounded-lg gap-[10px] p-[10px] overflow-y-auto'>
                    <div className='text-[16px] font-appFont text-[#2a877e] font-[600] underline w-full text-center py-2'>Select Car Model</div>
                    {

                        selectData?.length > 0 ? selectData.map((element, index) => {
                            return (
                                <button key={index}
                                    name='Vehicle_Model'
                                    value={element}
                                    className={`flex w-full border-[2px] font-appFont p-[5px] text-[14px] ${select === index ? 'border-[#2a877e] bg-[#2a877e] text-white' : 'border-[] bg-[#ffffff] text-[#bg-[#2a877e]]'} rounded cursor-pointer`}
                                    onClick={(e) => { setSelect(index); hide(); handleCarBrandModel(element); handleChange(e) }}>
                                    {element}
                                </button>
                            )
                        })
                            :
                            <div className='flex justify-center items-center h-full'>{"Select Car Brand please!"}</div>

                    }
                </div>

            </div>
        </div>
    )
}

export default ModelsPopUp

import React, { useEffect, useState } from 'react'
import FiltersView from './FiltersView'
import UserTable from './UserTable'
import { MdAddBox } from "react-icons/md";
import ModalWrapper from '../../modal/ModalWrapper';
import { Toaster } from 'react-hot-toast';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../../firestore';
import { useDispatch, useSelector } from 'react-redux';
import { SetAllUsers, SetFilterList } from '../../../../app/slices/UserSlice';
import * as XLSX from 'xlsx';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { State, City } from 'country-state-city';
import { SelectionAreas } from '../../../../app/jsons/SelectionAreas';



const TableViewData = () => {
  const [hideModa, setHideModal] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const All_State = State.getStatesOfCountry("IN")
  const [users, setUsersList] = useState({});
  const RefreshNow = useSelector(state => state.DataList.Refresh)
  const ListData = useSelector(state => state.DataList.UserList)
  const dispatch = useDispatch();

  const fetchList = async () => {
    console.log("refresh or not")
    const docRef = doc(db, "customers", "Sn3lz6kmewqCI43I5m0z");
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        dispatch(SetAllUsers(docSnap?.data()))
        dispatch(SetFilterList(docSnap?.data()))

        // setUsersList(docSnap?.data());
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.log("Error", error);
    }
  }


  //FETCH DATA FROM FIREBASE
  useEffect(() => {
    fetchList();
  }, [hideModa, activeModal])

  useEffect(() => {
    setUsersList(ListData)
  }, [ListData, RefreshNow]);
  // console.log(users,"list")
  const generateExcel = () => {
    const dataArray = users.userList.map((itms, index) => {

      const newEle = { ...itms, "State": State.getStatesOfCountry("IN")[itms.State].isoCode };
      const newEle2 = { ...newEle, "District": City.getCitiesOfState("IN", State.getStatesOfCountry("IN")[itms.State].isoCode)[itms.District].name }
      const newEle3 = { ...newEle2, "Colony_and_Area": SelectionAreas(itms.District, itms.Colony_and_Area) }
      const newEle4 = { ...newEle3, "Active": itms.Active === true ? "Active" : "De-active" }
      return newEle4;

    })
    const worksheet = XLSX.utils.json_to_sheet(dataArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet2');
    XLSX.writeFile(workbook, `${new Date().toDateString()}_customer.xlsx`);
  };


  return (
    <div className='bg-[#ffffff] flex flex-1 flex-col min-h-[calc(100vh-145px)] gap-[10px] pb-[15px]'>
      <Toaster />
      {hideModa && <ModalWrapper setHideModal={setHideModal} />}
      <div className='flex items-center justify-between '>

        <div className='bg-[#2a877e] py-[7px] px-[15px] rounded-lg'>
          <h2 className='text-[16px] font-semibold font-appFont text-[#ffffff]'>User list</h2>
        </div>
        <div className='flex gap-[10px]'>
          <button className='flex items-center gap-[5px] border-[1px] border-[#2a877e] py-[7px] px-[15px] font-semibold font-appFont rounded-lg text-[#2a877e]'
            onClick={generateExcel}>
            Excel
            <span className='text-[18px]'><RiFileExcel2Fill /></span></button>
          <button className='flex items-center gap-[5px] border-[1px] border-[#2a877e] py-[7px] px-[15px] font-semibold font-appFont rounded-lg text-[#2a877e]'
            onClick={() => { setHideModal(!hideModa) }}>
            Create Users
            <span className='text-[18px]'><MdAddBox /></span></button>
        </div>


      </div>
      <div className='border-b-2 mb-1 '></div>
      <div className='flex w-full'>
        <FiltersView />
      </div>
      <div className='border-b-2 my-1'></div>
      <div>
        <UserTable activeModal={activeModal} setActiveModal={setActiveModal} />
      </div>
    </div>
  )
}

export default TableViewData


export const MenuData = [
    {
        title: "HOME",
        url:"/",
        cName:"NavLinks",
    },
    {
        title: "SERVICES",
        url:"/Services",
        cName:"NavLinks",
    },
    {
        title: "PLANS",
        url:"/Plan",
        cName:"NavLinks",
    },
    {
        title: "ABOUT us",
        url:"/About",
        cName:"NavLinks",
    },
    // {
    //     title:"Sign In",
    //     url:"/SignInSignUp",
    //     cName:"SignUpbtn"
    // }
]
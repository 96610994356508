import Bhopal from './BhopalArea.json'
import Indore from "./IndoreArea.json"
export const SelectionAreas = (statecode,code) => {

        switch(statecode){
          case 52 :{
            return `${Bhopal.Bhopal[code]}`
          }
          case 102 :{
            return `${Indore.Indore[code]}`
          }
          default :{
            return `${Bhopal.Bhopal[code]}`
          }
        }
      
}



import React from 'react'
import { IoMdClose } from "react-icons/io";
import AddUserForm from '../addUser/AddUserForm';
const ModalWrapper = (props) => {
  const { setHideModal } = props;
  return (
    <div className='fixed top-0 right-0 left-0 bottom-0 backdrop-blur-sm bg-white/30 flex justify-center items-center z-[12]'>
      <div className='w-[410px] border-[1px] border-[#2a877e] bg-[#2a877e] h-[90vh]  shadow-lg rounded-xl p-[5px] flex flex-col gap-[2px] '>
        <div className='flex w-full justify-end  '>
          <span className='text-[20px] cursor-pointer text-white' onClick={() => { setHideModal(false) }}><IoMdClose /></span>
        </div>
          <AddUserForm show={setHideModal}/>

      </div>
    </div>
  )
}

export default ModalWrapper

import { createSlice } from "@reduxjs/toolkit";


export const UserSlice = createSlice({
    name: "usersSlice",
    initialState: {
        Refresh: false,
        UserList: {},
        filterList:{},
        TotalCustomer:0,
        Rating:215,
        Supervisor:1,
        MyCustomer:0,
    },
    reducers: {
        RefreshNow: (state, action) => {
            state.Refresh = action.payload;
        },
        SetAllUsers: (state, action) => {
            state.UserList = action.payload
            state.TotalCustomer =action.payload?.userList?.length;
            state.MyCustomer =action.payload?.userList?.length;
        },
        SetFilterList: (state, action) => {
            state.filterList = action.payload
            
        }
    }

})
export const { RefreshNow, SetAllUsers,SetFilterList } = UserSlice.actions
export default UserSlice.reducer
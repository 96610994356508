import React, { useEffect, useState } from 'react'
import { State, City } from 'country-state-city';
import { SelectionAreas } from '../../../../app/jsons/SelectionAreas';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from '@mui/material';
import { EnableActive } from '../../modal/EnableActive';
import AddUserWrapper from '../../appAdminFirebase/AddUserWrapper';
import { RefreshNow } from '../../../../app/slices/UserSlice';


const UserTable = ({ activeModal, setActiveModal }) => {
  const [users, setUsers] = useState()
  const filter = useSelector(state => state.DataList.filterList);
  const ActualList = useSelector(state => state.DataList.UserList);
  const [noData, setNoData] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const [userUID, setUserUID] = useState("");;
  const [userSingleData, setUserSingleData] = useState("")
  const refreshData = useSelector(state => state.DataList.Refresh)
  const [userActive, setUserActive] = useState("");



  useEffect(() => {

    if (ActualList?.userList?.length !== filter?.userList?.length) {
      if (filter?.userList?.length === 0) {
        setNoData(true);
        setUsers(filter)
      } else {
        setUsers(filter)
        setNoData(true);
      }
    } else {
      setUsers(ActualList);

    }
  }, [filter, ActualList, refreshData]);

  const SetActiveUser = (itms, ID, Name, Active) => {
    setUserName(Name);
    setUserActive(Active);
    setUserSingleData(itms)
    setUserUID(ID)
    setActiveModal(!activeModal);
    setLoading(false)

  }

  const UpdateFirebaseDoc = async () => {
    setLoading(true);
    // let Edit = [...ActualList?.userList]
    const spread = { ...userSingleData, "Active": !userSingleData.Active };
    console.log(spread)
    const updatedList = ActualList.userList.map((ele, index) => {
      if (ele.uuid === userUID) {
        return spread
      } else {
        return ele
      }
    });
    // console.log(updatedList, "updated");
    setTimeout(() => {
      const update = AddUserWrapper({ userDetails: {}, addUser: false, UpdateUser: updatedList });
      if (update) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setActiveModal(false);
          dispatch(RefreshNow(false))
        }, 1000);


      } else {
        setTimeout(() => {
          setActiveModal(false);
          setLoading(false);
        }, 1000);

      }
    }, 1000);
  }


console.log(users)

  return (
    <div className='flex flex-col gap-[10px]'>
      <div className='flex w-full border-[1px] p-[10px] rounded-md shadow-md gap-[10px]'>
        <div className='w-[5%] font-appFont flex justify-center text-[14px] font-[600] text-[#298f87] min-w-max'>UID</div>
        <div className='w-[12%] font-appFont flex justify-center text-[14px] font-[600] text-[#298f87] min-w-max'>Full Name</div>
        <div className='w-[10%] font-appFont flex justify-center text-[14px] font-[600] text-[#298f87] min-w-max'>Phone Number</div>
        <div className='w-[25%] font-appFont flex justify-center text-[14px] font-[600] text-[#298f87] min-w-max'>Vehicle Brand & Model</div>
        <div className='w-[10%] font-appFont flex justify-center text-[14px] font-[600] text-[#298f87] min-w-max'>Vehicle No</div>
        <div className='w-[10%] font-appFont flex justify-center text-[14px] font-[600] text-[#298f87] min-w-max'>Vehicle Color</div>
        <div className='w-[5%] font-appFont flex justify-center text-[14px] font-[600] text-[#298f87] min-w-max'>Time Slot</div>
        <div className='w-[23%] font-appFont flex justify-center text-[14px] font-[600] text-[#298f87] min-w-max'>Address</div>
        <div className='w-[1%] font-appFont flex justify-center text-[14px] font-[600] text-[#298f87] min-w-max'>Status</div>
      </div>
      <div className='userListScroll max-h-[calc(100vh-340px)] flex flex-col gap-[10px]'>
        {
          users && users.userList && users.userList.length > 0 ? users.userList?.map((ele, index) => {
            return (
              <div key={index} className='flex w-full border-[1px] p-[10px] rounded-md shadow-md gap-[10px] items-center'>
                <div className='w-[5%] font-appFont flex justify-center text-[13px] min-w-max'>{ele.uuid.slice(0, 8)}...</div>
                <div className='w-[12%] font-appFont flex justify-center text-[13px] min-w-max capitalize'>{ele.Full_Name}</div>
                <div className='w-[10%] font-appFont flex justify-center text-[13px] min-w-max'>{ele.Phone_Number}</div>
                <div className='w-[25%] font-appFont flex justify-center text-[13px] min-w-max'>{`${ele.Vehicle_Brand}-${ele.Vehicle_Model}`}</div>
                <div className='w-[10%] font-appFont flex justify-center text-[13px] min-w-max'>{ele.Vehicle_Number}</div>
                <div className='w-[10%] font-appFont flex justify-center text-[13px] min-w-max capitalize'>{ele.Vehicle_Color}</div>
                <div className='w-[5%] font-appFont flex justify-center text-[13px] min-w-max'>{ele.Time}</div>
                <div className='w-[23%] font-appFont flex justify-center text-[13px] min-w-max capitalize'>
                  {`${ele.House_Number},${ele.Colony}, ${SelectionAreas(ele.District, ele.Colony_and_Area)}, ${City.getCitiesOfState("IN", "MP")[ele.District].name} 
              (${State.getStatesOfCountry("IN")[ele.State].isoCode})`}

                </div>
                <div onClick={() => { SetActiveUser(ele, ele.uuid, ele.Full_Name, ele.Active) }}><Switch checked={ele?.Active} size="small" id={`active${index}`} color="success" /></div>
                {
                  activeModal &&
                  <EnableActive
                    hide={SetActiveUser}
                    handleClick={UpdateFirebaseDoc}
                    name={userName}
                    status={userActive}
                    isLoading={isLoading} />
                }

              </div>
            )
          }) :
            <div>
              {noData && <div className='flex w-full border-[1px] p-[10px] justify-center rounded-md shadow-md gap-[10px]'>No Data Found!</div>}
              <div className="min-h-[150px] flex justify-center items-center bg-white">

                <div className="loader bg-[#298f87] p-3 rounded-full flex space-x-3">
                  <div className="w-3 h-3 bg-white rounded-full animate-bounce"></div>
                  <div className="w-3 h-3 bg-white rounded-full animate-bounce"></div>
                  <div className="w-3 h-3 bg-white rounded-full animate-bounce"></div>
                  <div className="w-3 h-3 bg-white rounded-full animate-bounce"></div>
                  <div className="w-3 h-3 bg-white rounded-full animate-bounce"></div>
                  <div className="w-3 h-3 bg-white rounded-full animate-bounce"></div>
                </div>

              </div>
            </div>
        }
      </div>

    </div>
  )
}

export default UserTable

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IoMdClose } from "react-icons/io";
import { State, City } from 'country-state-city';
import BhopalArea from '../../../../app/jsons/BhopalArea.json'
import IndoreArea from '../../../../app/jsons/IndoreArea.json'
import { useDispatch, useSelector } from 'react-redux';
import { SelectionAreas } from '../../../../app/jsons/SelectionAreas';
import { SetFilterList } from '../../../../app/slices/UserSlice';
import { RiFileExcel2Fill } from "react-icons/ri";

const FiltersView = () => {
    // const [sort, setsort] = useState(0)
    const All_State = State.getStatesOfCountry("IN")
    const [state, setState] = useState(19);
    const [city, setCity] = useState(52);
    const [areas, setAreas] = useState(0);
    const users = useSelector(state => state.DataList.UserList);
    const dispatch = useDispatch()
    // 102 city code for INDORE
    // 52 city code for BHOPAL

    function HandleArea(e) {

        const { value } = e.target;
        // if(value===0)return
        setAreas(value)
        const checkString = SelectionAreas(value);
        console.log(checkString)
        const filterData = users?.userList.filter((ele, index) => ele.Colony_and_Area === value)
        const obj = { userList: filterData }
        console.log(obj)
        dispatch(SetFilterList(obj))
    }
    const handleState = (value) => {
        setState(value);
        setCity(0);
        setAreas(0);

    }
    const handleCity = (value) => {
        setCity(value);
        setAreas(0);
    }
    const ClearFilters = () => {
        setCity(52);
        setState(19);
        setAreas(0);
        dispatch(SetFilterList(users))

    }

    const filters = {
        sort: ["Select Sort", "Sort A to Z", "Sort Z to A"],
        state: State.getStatesOfCountry("IN"),
        city: City.getCitiesOfState("IN", All_State[state ? state : 0]?.isoCode),
        areas: city === 52 ? BhopalArea.Bhopal : city === 102 ? IndoreArea.Indore : ["no data "]
    }




    return (
        <div className='flex  justify-between w-[100%]'>
            <div className='flex w-[60%] gap-[15px]'>
                <FormControl className='w-[25%]'>
                    <InputLabel id="demo-simple-select-label" className=''>Select Locality</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        value={areas}
                        label="Select Locality"
                        className='text-[5px]'
                        placeholder='Select State'
                        onChange={HandleArea}
                        size='small'
                        style={{ fontFamily: "Poppins", fontSize: "14px", width: "100%" }}
                    >
                        {filters.areas.map((ele, index) => {
                            return (
                                <MenuItem key={index} value={index} style={{ fontFamily: "Poppins", fontSize: "14px" }}>{ele}</MenuItem>
                            )
                        })}

                    </Select>
                </FormControl>
                <FormControl className='w-[25%]'>
                    <InputLabel id="demo-simple-select-label" className=''>Select City</InputLabel>
                    <Select
                        value={city}
                        className='text-[5px] w-[100%]'
                        placeholder='Select City'
                        label="Select City"
                        onChange={(e) => { handleCity(e.target.value) }}
                        size='small'
                        style={{ fontFamily: "Poppins", fontSize: "14px" }}
                    >
                        {filters.city.map((ele, index) => {
                            return (
                                <MenuItem key={index} value={index} style={{ fontFamily: "Poppins", fontSize: "14px" }}>{ele.name}</MenuItem>
                            )
                        })}

                    </Select>
                </FormControl>
                <FormControl className='w-[25%]'>
                    <InputLabel id="demo-simple-select-label" className=''>Select State</InputLabel>
                    <Select
                        value={state}
                        className='text-[5px] w-[100%]'
                        placeholder='Select State'
                        label="Select State"
                        onChange={(e) => { handleState(e.target.value) }}
                        size='small'
                        style={{ fontFamily: "Poppins", fontSize: "14px" }}
                    >
                        {filters.state.map((ele, index) => {
                            return (
                                <MenuItem key={index} value={index} style={{ fontFamily: "Poppins", fontSize: "14px" }}>{ele.name}</MenuItem>
                            )
                        })}

                    </Select>
                </FormControl>

            </div>

            <button className='flex gap-1 items-center font-appFont text-[14px] w-max
            outline-none border-[1px] bg-[#ffeeee] rounded-lg justify-center px-[10px]'
                onClick={ClearFilters}
            >
                Clear filters
                <span className=''><IoMdClose /></span>
            </button>

        </div>
    )
}

export default FiltersView

export const DoorStep = [
    {
        price:"Rs - 699"
    },
    {
        planDetails:"Exterior cleaning"
    },
    {
        planDetails:"Glass cleaning"
    },
    {
        planDetails:"Tyre Polishing"
    },
 
]
export  const Outlet = [
    {
        price:"Rs - 699"
    },
    {
        planDetails:"Complete Car Wash."
    },
    {
        planDetails:"Interior Vacuum."
    },
    {
        planDetails:"Dashboard & Tyre Polish."
    }
]
export const Hybrid = [
    {
        price:"Rs - 1099"
    },
    {
        planDetails:"Full Car Wash."
    },
    {
        planDetails:"Plan Includes both ( DoorStep and Outlet)."
    },

    {
        planDetails: "24x7 Services for Car Lovers."
    }
]

import React, { useState } from 'react'
import { AiOutlineLoading3Quarters } from "react-icons/ai";
export const EnableActive = ({ hide, handleClick, status, name, isLoading }) => {
    
    return (
        <div className='fixed top-0 right-0 left-0 bottom-0 backdrop-blur-sm bg-white/40 flex justify-center items-center z-[12]'>
            <div className='w-[410px]  border-[#e7e7e7] bg-white h-[200px] shadow-lg rounded-xl flex flex-col justify-between p-[15px] border-[1px]'>
                <div className='flex flex-col gap-[15px]'>
                    <h2 className='text-[1em] font-appFont text-[#298f87] capitalize font-[500]'>{`Do You Want to ${status ? 'InActive' : 'Active'} ${name} ?`}</h2>
                    <p className='text-[14px] font-appFont text-[#298f87]'>{`Press Confirm To ${status ? 'InActive' : 'Active'} This User...!`}</p>
                </div>
                <div className='flex gap-[10px]'>
                    {isLoading ?
                        <div className='text-white font-[500] flex justify-center items-center p-[10px] font-appFont text-[16px] oultine-none bg-green-300 hover:bg-green-400 rounded-lg w-[50%]'>
                          <span className='text-[20px] animate-spin'>< AiOutlineLoading3Quarters/></span>
                        </div>
                        : <button
                            className='text-white font-[500] p-[10px] font-appFont text-[16px] oultine-none bg-green-300 hover:bg-green-400 rounded-lg w-[50%]'
                            onClick={handleClick} >
                            Confirm
                        </button>}

                    <button
                        className='text-white font-[500] p-[10px] font-appFont text-[16px] oultine-none bg-red-300 hover:bg-red-400 rounded-lg  w-[50%]'
                        onClick={hide}>
                        Cancel
                    </button>

                </div>
            </div>
        </div>
    )
}


const BrandsModel = () => {

    const Brands = [
        "Select Car Brand",
        "Maruti Suzuki",
        "Hyundai",
        "Tata",
        "Mahindra",
        "Honda",
        "Toyota",
        "Volkswagen",
        "Ford",
        "Renault",
        "Nissan",
        "Chevrolet",
        "Skoda",
        "Fiat"
    ];
   return Brands

}

export default BrandsModel

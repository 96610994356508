import './App1.css';
import HomePage from './Home';
function App() {
// const apikey = "fe79cd45a8884fe38483b89cc613a8af"
  return (
    <div>
   <HomePage />
   </div>
    );
}
export default App;


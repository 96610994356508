import React, { useState } from 'react'
import Logo from '../../img/webLogo.png'
import { HeaderTopSections } from './sidebar/SideBarSections'
import { useNavigate } from 'react-router-dom'
import LogoutModal from './modal/LogoutModal'
const AdminHeader = ({removeCookie}) => {
  const navigate = useNavigate();
  const [logoutModal,setLogoutModal] =useState(false);
  return (
    <div className='flex justify-center'>

      {logoutModal&& <LogoutModal setLogoutModal={setLogoutModal} removeCookie={removeCookie}/>}
      <div className='pl-[25px] pr-[15px] sticky top-0 flex w-[100%] justify-between shadow-md border-t-[1px] border-[#a4cbc791]'>
        <div className='w-[150px] h-[80px] cursor-pointer' >
          <img src={Logo} alt='' className='w-[100%] h-[100%] ' onClick={()=>{navigate("/")}}/>
        </div>
        <div className='flex gap-[10px] justify-center items-center'>
          <div className='flex gap-[10px]'>
            {HeaderTopSections.map((ele, index) => {
              return (
                <div className={index === 0 ?
                 'bg-[#f3f6f9]  shadow-md shadow-slate-200 text-[22px] px-[10px] flex items-center cursor-pointer rounded-lg text-[#298f87]' 
                 : 'bg-[#f3f6f9] shadow-md shadow-slate-200 text-[22px] cursor-pointer flex p-3 rounded-lg text-[#298f87]'} key={index}>
                  {index === 0 ? <div className='flex '>
                    <input className='bg-[#f3f6f9] text-[14px] outline-none px-[10px] ' type="text"  placeholder='Search Users by Number'/>{ele.icon}
                  </div>
                    :
                    ele.icon
                  }
                </div>
              )
            })}
          </div>

          <div className='bg-[#f3f6f9] p-[0.7em] rounded-lg flex justify-center items-center gap-[2px] cursor-pointer  shadow-md shadow-slate-200' onClick={()=>setLogoutModal(true)} >
            <span className='text-[16px] text-[#298f87]'>{'R'}</span>
            <span className='text-[16px] text-[#298f87]'>{'P'}</span>
          </div>

        </div>
      </div>
    </div>
  )
}

export default AdminHeader

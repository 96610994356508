import React from 'react'
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

const LogoutModal = ({ setLogoutModal }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['name'])
  const navigate = useNavigate();

  function HandleLogout() {
    removeCookie(["gowashe_token"]);
    setLogoutModal(false);
    setTimeout(() => {
      navigate("/");
    }, 1000);

  }

  return (
    <div className='fixed top-0 right-0 left-0 bottom-0 backdrop-blur-sm bg-white/40 flex justify-center items-center z-[12]'>
      <div className='w-[410px]  border-[#e7e7e7] bg-white h-[200px] shadow-lg rounded-xl flex flex-col justify-between p-[15px] border-[1px]'>
        <div className='flex flex-col gap-[15px]'>
          <h2 className='text-[1.5em] font-appFont text-[#298f87] font-[500]'>Do You Want to Exit Admin Mode ?</h2>
          <p className='text-[1em] font-appFont text-[#298f87]'>Press Confirm Exit Admin mode it...!</p>
        </div>
        <div className='flex gap-[10px]'>
          <button
            className='text-white font-[500] p-[10px] font-appFont text-[16px] oultine-none bg-green-300 hover:bg-green-400 rounded-lg w-[50%]'
            onClick={HandleLogout} >
            Confirm
          </button>

          <button
            className='text-white font-[500] p-[10px] font-appFont text-[16px] oultine-none bg-red-300 hover:bg-red-400 rounded-lg  w-[50%]'
            onClick={() => { setLogoutModal(false) }}>
            Cancel
          </button>

        </div>
      </div>
    </div>
  )
}

export default LogoutModal

import React from 'react'
import Sidebar from './sidebar/Sidebar'
import MainSection from './main/MainSection'

const AdminLayout = () => {
  return (
    <div className='flex flex-1 max-h-[calc(100vh-83.5px)] transition ease-in-out duration-500 bg-[#f3f6f9] p-[15px] gap-[15px] userListScroll'>
      <Sidebar/>
      <MainSection/>
    </div>
  )
}

export default AdminLayout

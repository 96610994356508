import React, { useState } from 'react'
import { SideBarSections } from './SideBarSections'
import { MdOutlineMenuOpen } from "react-icons/md";
import { RiMenuFoldLine } from "react-icons/ri";
import { RiMenuUnfoldLine } from "react-icons/ri";
const Sidebar = () => {
  const [styleclass, setstyleclass] = useState(0)
  const [toggle, setToggle] = useState(false)

  return (
    <div className='flex sticky top-0'>

      <div className={
        !toggle ? 'transition-all duration-500 ease-out w-[200px] py-[5px] border-[2px] border-[#d1d1d1] rounded-lg shadow-lg bg-[#ffffff]' :
          '  h-[100%] transition-all duration-500 ease-out w-[75.8px] py-[5px] border-[2px] border-[#d1d1d1] rounded-lg shadow-lg bg-[#ffffff]'}>

        <div className='p-3 flex flex-col gap-[10px]' >
          <div className=' flex items-center justify-between text-[#298f87] border-[2px] p-[10px] border-[#d1d1d1] rounded-[5px] shadow-lg'>
            {!toggle ? <p className='text-[14px] font-appFont font-[600]'>Menus</p>:""}
           
            <p className='text-[25px] cursor-pointer ' onClick={() => setToggle(!toggle)}>{!toggle ? <RiMenuFoldLine /> :<RiMenuUnfoldLine/>}</p>
          </div>
          {/* <h2 className='text-[16px] '>Main</h2> */}
          <div className='flex flex-col gap-[10px]'>
            {SideBarSections.map((ele, index) => {
              return (
                <div className={styleclass !== index ? 'flex gap-[10px] border-[1.5px] rounded p-[10px] border-[#298f87] justify-start items-center shadow-lg cursor-pointer'
                  : 'flex gap-[10px] border-[1.5px] rounded p-[10px] border-[#298f87] bg-[#298f87] justify-start items-center shadow-lg cursor-pointer'}
                  key={index}
                  onClick={() => setstyleclass(index)}
                >
                  <span className={styleclass !== index ?
                    'text-[25px] pl-[2px] text-[#298f87]' :
                    'text-[25px] pl-[2px] text-[#ffffff]'
                  }>{ele.icon}</span>
                  {!toggle ? 
                  <p className={styleclass !== index ? 'text-[16px] text-[#298f87] font-semibold ' : 'text-[16px] text-[#ffffff] font-[400]'} style={{ fontFamily: "'Poppins', sans-serif" }}>{ele.title}</p>
                  :null}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar

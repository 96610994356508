import React from 'react'
import DataView from '../dataSections/dataView/DataView'
import TableViewData from '../dataSections/tableView/TableViewData'
import "./MainSection.css"
const MainSection = () => {
  return (
    <div className='flex flex-1 flex-col gap-[15px] '>
      <div className='flex h-max p-[15px] border-[2px] border-[#d1d1d1] rounded-lg shadow-lg bg-[#ffffff] flex-col gap-[10px]'>
        <DataView />
      </div>
      <div className='flex h-[calc(100vh-114px)] p-[15px] border-[2px] border-[#d1d1d1] rounded-lg shadow-lg bg-[#ffffff] flex-col gap-[10px]'>
        <TableViewData />
      </div>

    </div>
  )
}

export default MainSection

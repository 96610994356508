import React, { useEffect, useState } from 'react'
import { SiGmail } from "react-icons/si";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";
import { CgSpinner } from 'react-icons/cg';
import { useFormik } from 'formik';
import * as Yup from "yup";
import {getAuth,signInWithEmailAndPassword} from 'firebase/auth'
import toast, { Toaster } from 'react-hot-toast';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
const Login = () => {
  const [type, setType] = useState(false);
  const [loading, setloading] = useState(false);
  const [cookies, setCookie] = useCookies(['name']);
  const navigate = useNavigate()
  useEffect(() => {
    if (cookies['gowashe-token']) {
      navigate("/admin")
    } else {
      navigate("/admin/login")
    }
  },[])

  const LoginSchema = Yup.object().shape({
    email:Yup.string().email().required("Enter Admin Gmail"),
    password:Yup.string().min(6).required("Enter Password Please"),
  })
  const {values,errors,touched,handleChange,handleSubmit} = useFormik({

    initialValues:{
      email:"",
      password:"",
    },
    validationSchema:LoginSchema,
    onSubmit:(values)=>{
      setloading(true)
      const auth = getAuth();
      signInWithEmailAndPassword(auth,values.email,values.password)
      .then((userCredential)=>{
        const Admin = userCredential.user;
        console.log(Admin.uid);
        var SevenDays = new Date();
        SevenDays.setDate(SevenDays.getDate()+7);
        setCookie("gowashe_token",Admin.uid,{expires:SevenDays});
        toast.success("Login Successfully");
        setloading(false);
        navigate('/admin')
      })
      .catch((error) => {
        const errorCode = error.code;
        toast.error(errorCode === "auth/wrong-password" ? "Wrong Password" :
         errorCode === "auth/user-not-found" ? "Wrong Admin Gmail Please Check..!" :  " Failed To Login Please Try Later..!");
        setloading(false)
      });
    }

  })

  return (
    <div className=' flex flex-1 justify-center items-center  min-h-screen '>
      <Toaster/>
      <div className='flex w-[350px] flex-col justify-evenly border-[2px] py-[20px] px-[15px] border-[#298f87] rounded-lg h-[75vh] shadow-lg shadow-cyan-950'>
        <div className='flex w-full justify-start flex-col gap-[10px] py-[10px] items-center'>
          <h1 className="text-center leading-normal  text-[#ffffff] font-medium text-[25px]">
            <span className='font-appFont font-[500]'>Welcome To </span>
            <b style={{ color: "rgb(80, 234, 80)" }} className='font-appFont'>GO</b>
            <b style={{ color: "rgb(0, 128, 255)" }} className='font-appFont'>WASH</b>
            <b style={{ color: "red" }}>E</b>
            <span style={{ color: "gray" }}>xpert</span>
          </h1>
          <p className='font-appFont text-[#ffffff] text-[17px]'>Admin Login</p>
        </div>
        <div className='flex w-full justify-center'>
          <div className='bg-[#ffffff] p-[15px] rounded-full'>
            <span className='text-[#298f87] text-[25px]'><SiGmail /></span></div>
        </div>
        <form className='flex flex-col gap-[20px]' onSubmit={handleSubmit}>

       
        <div className='flex flex-col w-full items-center gap-[15px]'>
          <p className='text-[#ffffff] text-[16px] font-appFont'>Verify Admin Gmail</p>
          <div className={`w-full flex flex-row items-center rounded border-[2px] bg-white   px-[10px] ${errors.email && touched.email? 'border-red-500':'border-white ' } `}>
            <input 
            placeholder='Enter Gmail' 
            name='email' 
            type="email" 
            value={values.email}
            onChange={handleChange}
            className='text-[15px] py-[10px] font-appFont bg-[transparent] w-full outline-none' />
            <span className='text-[#298f87] text-[18px] bg-[transparent]'><MdAlternateEmail /></span>
          </div>
          


          <div className={`w-full flex flex-row items-center rounded px-[10px] border-[2px]  bg-white ${errors.password && touched.password? 'border-red-500 ':'border-white bg-[#ffffff]' } `}>
            <input 
            placeholder='Enter Password' 
            type={!type ? "password" : "text"} 
            name='password'
            value={values.password}
            onChange={handleChange}
            className='text-[15px] py-[10px] font-appFont w-full outline-none  bg-[transparent]' />
            {!type ?
              <span onClick={() => setType(!type)} className='text-[#298f87] bg-[transparent] text-[18px]'><FaEyeSlash /></span>
              : <sapn onClick={() => setType(!type)} className='text-[#298f87]  bg-[transparent]text-[18px]'><FaEye /></sapn>}
          </div>
        </div>
        <div className='flex w-full items-center border-[1px] gap-[10px] justify-center border-emerald-600 bg-emerald-600 p-[5px] rounded'>
          {loading ?
            <span className=''>
              <CgSpinner size={25} className="mt-1 animate-spin text-[#ffffff]" />
            </span> :
            <button type="submit"
              className='text-[16px] w-full font-appFont text-[#ffffff] outline-none border-none'>Login</button>
              }


        </div>
        </form>
      </div>
    </div>
  )
}

export default Login
